
import http from '@/utils/http'
import { defineComponent, onMounted, ref } from 'vue'
import { format_date } from '../../../utils/index'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: 'App类型',
    dataIndex: 'app_type',
    key: 'app_type',
    align: 'center'
  },
  {
    title: '版本',
    dataIndex: 'ver',
    key: 'ver',
    align: 'center'
  },
  {
    title: '补丁列表',
    dataIndex: 'patches',
    key: 'patches',
    slots: { customRender: 'patches' },
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: '',
    key: '',
    slots: { customRender: 'edit' },
    align: 'center'
  }
]

export default defineComponent({
  name: 'PatchesUrl',
  setup () {
    const total = ref(0)
    const currentPage = ref(1)
    const dataSource = ref([])
    const iconLoading = ref(false)
    const visible = ref(false)
    const form = ref({ app_key: '' })
    const modalTitle = ref('')
    const modalData = ref({
      app_type: '',
      ver: '',
      patches: ''
    })

    const paginationChange = (page: any) => {
      currentPage.value = page
      getList()
    }

    const getList = () => {
      http(`/tokenapi/patches?page=${currentPage.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          dataSource.value = res.data
          total.value = dataSource.value.length
        }
      })
    }

    const setting = (data: any) => {
      visible.value = true
      modalTitle.value = '配置'
      modalData.value = {
        app_type: data.app_type,
        ver: data.ver,
        patches: data.patches.join(',')
      }
      
    }

    const handleOk = () => {
      http(`/tokenapi/patches`, {
        method: 'post',
        body: JSON.stringify({
          ...modalData.value,
          patches: modalData.value.patches.split(',')
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          visible.value = false
          getList()
        }
        message.info(res.msg)
      })
    }

    const add = () => {
      visible.value = true
      modalTitle.value = '新增'
      modalData.value = {
        app_type: '',
        ver: '',
        patches: ''
      }
    }

    const del = (data: any) => {
      http(`/tokenapi/patches`, {
        method: 'delete',
        body: JSON.stringify({
          app_type: data.app_type,
          ver: data.ver
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          getList()
        }
        message.info(res.msg)
      })
    }

    onMounted(() => {
      getList()
    })

    return {
      columns,
      total,
      currentPage,
      paginationChange,
      dataSource,
      iconLoading,
      form,
      format_date,
      visible,
      setting,
      modalData,
      handleOk,
      add,
      modalTitle,
      del
    }
  }
})
