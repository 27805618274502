<template>
  <div class="patches_url">
    <div class="search-bar">
      <a-button type="primary" @click="add">新增</a-button>
    </div>
    <a-table :dataSource="dataSource" :columns="columns" :row-key="record => record.rowKey" :size="'small'" :pagination="false" :loading="iconLoading">
      <template #Type="{ record }">
        <span>{{ record.Type === '0' ? '管理员' : '普通用户' }}</span>
      </template>
      <template #patches="{ record }">
        <div v-for="(item, i) in record.patches" :key="i">{{ item }}</div>
      </template>
      <template #edit="{ record }">
        <a-button type="link" @click="setting(record)">配置</a-button>
        <a-popconfirm
          title="确认删除?"
          ok-text="确认"
          cancel-text="取消"
          @confirm="del(record)"
        >
          <a href="#">删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    <a-pagination
      :total="total"
      :show-total="total => `总共 ${total} 条`"
      :page-size="100"
      :size="'small'"
      v-model:current="currentPage"
      @change="paginationChange"
    />
    <a-modal v-model:visible="visible" :title="modalTitle" @ok="handleOk" okText="保存" cancelText="取消">
      <div style="margin-bottom: 10px">
        <span>App类型：</span>
        <a-input style="display: inline; width: 85%" v-model:value="modalData.app_type" />
      </div>
      <div style="margin-bottom: 10px">
        <span>补丁版本：</span>
        <a-input style="display: inline; width: 85%" v-model:value="modalData.ver" />
      </div>
      <div style="margin-bottom: 10px">
        <span>补丁列表：</span>
        <a-textarea style="display: inline; width: 85%" v-model:value="modalData.patches" placeholder="多个请用英文逗号隔开" :rows="3" />
      </div>
    </a-modal>  
  </div>
</template>

<script lang="ts">
import http from '@/utils/http'
import { defineComponent, onMounted, ref } from 'vue'
import { format_date } from '../../../utils/index'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: 'App类型',
    dataIndex: 'app_type',
    key: 'app_type',
    align: 'center'
  },
  {
    title: '版本',
    dataIndex: 'ver',
    key: 'ver',
    align: 'center'
  },
  {
    title: '补丁列表',
    dataIndex: 'patches',
    key: 'patches',
    slots: { customRender: 'patches' },
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: '',
    key: '',
    slots: { customRender: 'edit' },
    align: 'center'
  }
]

export default defineComponent({
  name: 'PatchesUrl',
  setup () {
    const total = ref(0)
    const currentPage = ref(1)
    const dataSource = ref([])
    const iconLoading = ref(false)
    const visible = ref(false)
    const form = ref({ app_key: '' })
    const modalTitle = ref('')
    const modalData = ref({
      app_type: '',
      ver: '',
      patches: ''
    })

    const paginationChange = (page: any) => {
      currentPage.value = page
      getList()
    }

    const getList = () => {
      http(`/tokenapi/patches?page=${currentPage.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          dataSource.value = res.data
          total.value = dataSource.value.length
        }
      })
    }

    const setting = (data: any) => {
      visible.value = true
      modalTitle.value = '配置'
      modalData.value = {
        app_type: data.app_type,
        ver: data.ver,
        patches: data.patches.join(',')
      }
      
    }

    const handleOk = () => {
      http(`/tokenapi/patches`, {
        method: 'post',
        body: JSON.stringify({
          ...modalData.value,
          patches: modalData.value.patches.split(',')
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          visible.value = false
          getList()
        }
        message.info(res.msg)
      })
    }

    const add = () => {
      visible.value = true
      modalTitle.value = '新增'
      modalData.value = {
        app_type: '',
        ver: '',
        patches: ''
      }
    }

    const del = (data: any) => {
      http(`/tokenapi/patches`, {
        method: 'delete',
        body: JSON.stringify({
          app_type: data.app_type,
          ver: data.ver
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          getList()
        }
        message.info(res.msg)
      })
    }

    onMounted(() => {
      getList()
    })

    return {
      columns,
      total,
      currentPage,
      paginationChange,
      dataSource,
      iconLoading,
      form,
      format_date,
      visible,
      setting,
      modalData,
      handleOk,
      add,
      modalTitle,
      del
    }
  }
})
</script>

<style lang="scss">
.patches_url {
  .search-bar {
    height: 40px;
    margin-bottom: 15px;

    .label {
      width: 40px;
      display: inline;

      .anticon {
        margin: 0 3px;
      }
    }

    .ant-input {
      display: inline;
      width: 250px;
      margin: 0 10px;
    }

    .ant-btn {
      margin: 0 10px;
    }
  }

  .ant-pagination {
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
  }
}

.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>
